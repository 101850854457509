$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$orchid: #D883BF;
$wisteria: #B5ACD3;
$halfbaked: #8CB9DA;

$primary-font: "area-normal";
$header-font: "chantal";
