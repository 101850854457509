@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
	width: 100%;
	overflow-x: hidden;
	cursor: none;
}

a {
	color: $white;
	font-family: $primary-font;

	@include hover-focus {
		cursor: none;
		color: $orchid;
	}
}

h2 {
	font-family: $header-font;
	font-size: rems(125);
	color: $black;
	padding: 0;
	margin: 0;
}

// CAROUSEL
header {
	position: relative;

	.fade {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
	}


	.container {
		position: relative;
		z-index: 2;

		.nav-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;

			&.active {
				background-color: $black;
			}

			@include tablet-down {
				flex-direction: column;
			}

			h1#logo {
				display: block;
				font-family: $header-font;
				color: $orchid;
				font-size: rems(60);
				pointer-events: all;
				background: url('../img/glitter.gif') center center no-repeat;
				background-size: cover;
				background-clip: text;
				-webkit-background-clip: text;
				transition: all .5s ease-in-out;
				padding-left: rems(16);

				@include hover-focus {
					color: transparent;
				}

				@media screen and (max-width: 1100px) {
					font-size: rems(65);
					width: auto;
					padding: 0;
				}

				@include tablet-down {
					font-size: rems(60);
				}

				@include phone-down {
					font-size: rems(40);
				}
			}






			nav#navigation {
				display: flex;
				justify-content: space-between;
				width: 100%;

				@include tablet-down {
					flex-direction: column;
					align-items: center;
					display: none;
					margin-left: 0;

					&.active {
						display: flex;
					}
				}

				ul#nav-menu {
					display: flex;
					align-items: center;
					list-style: none;
					width: 100%;
					justify-content: space-between;
					padding: 0 rems(20) 0 rems(30);

					@include tablet-down {
						flex-direction: column;
						gap: rems(50);
					}

					.nav-item {
						a {
							font-size: rems(18);
							text-transform: uppercase;
						}
					}
				}

				.social {
					display: flex;
					align-items: center;
					list-style: none;
					gap: rems(12);
					padding: 0 0 0 rems(25);
					min-width: rems(150);
					max-width: rems(220);

					@media screen and (min-width: $break-point-phone-max) {
						margin-right: rems(16);
					}

					li {
						@include tablet-down {
							margin: 0 auto;
						}

						a {
							img {
								transition: all .3s ease-in-out;

								@include hover-focus {
									transform: scale(1.3);
								}
							}
						}
					}
				}
			}

			.mobile-menu-wrapper {
				padding: rems(25);
				position: absolute;
				// top: 50%;
				// transform: translateY(-50%);
				top: 0;
				right: 0;

				@include phone-down {
					padding: rems(22);
				}

				.mobile-menu-icon {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					pointer-events: all;
					// cursor: pointer;
					position: relative;
					z-index: 30;

					// ICON SIZE
					width: 35px;
					height: 40px;

					@media screen and (min-width: 992px) {
						display: none;
					}
				}

				.line {
					width: 100%;
					height: 2px;
					background-color: $orchid;
					margin: 4.5px;
					transition: transform 0.3s ease;
					transform-origin: center;


					&.active {
						transform: rotate(45deg) !important;
						position: absolute;
						top: 30%;

						&.active:nth-child(2) {
							display: none;
						}

						&.active:nth-child(3) {
							transform: rotate(-45deg) !important;
						}
					}
				}
			}
		}
	}

	#background-video {
		width: 100%;
		height: auto;
		z-index: -100;
		background-size: cover;
		background-position: center;
		overflow: hidden;
	}
}

main {

	#music,
	#videos,
	#instagram {
		padding: rems(124) 0;

		@include tablet-down {
			padding: rems(100) 0;
		}

		.container {}
	}

	section#music {
		background-color: $orchid;
		background-image: url(../img/bg/bg1.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		.container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;


			&::after {
				content: "";
				position: absolute;
				bottom: rems(40);
				left: 75%;
				width: rems(120);
				height: rems(170);
				pointer-events: none;
				background: url('../img/icon/decals/pair-2.png') no-repeat;
				background-size: contain;

				@media screen and (max-width: 1299px) {
					left: 83%;
					bottom: rems(-20);
				}

				@media screen and (max-width: 699px) {
					left: 88%;
					bottom: rems(-20);
				}

				@media screen and (max-width: 590px) {
					left: 100%;
					bottom: rems(-20);
				}

				// @include tablet-down {
				// 	left: rems(-200);
				// }

				// @include phone-down {
				// 	left: rems(-75);
				// }
			}

			h2 {
				text-align: center;
				margin-bottom: rems(32);
				position: relative;

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: rems(-250);
					width: rems(120);
					height: rems(170);
					pointer-events: none;
					background: url('../img/icon/decals/pair-1.png') no-repeat;
					background-size: contain;

					@include tablet-down {
						left: rems(-200);
						top: rems(-30);
					}

					@include phone-down {
						left: rems(-75);
						top: rems(-30);
					}
				}
			}

			#music-carousel {
				.item {
					max-width: rems(600);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					margin: 0 auto;

					img {
						margin-bottom: rems(77);
					}

					a {
						padding: rems(28) rems(172);
						background-color: $black;
						color: $white;
						font-family: $header-font;

						transition: all .3s ease-in-out;

						@include hover-focus {
							background-color: $wisteria;
						}
					}
				}

				.owl-custom-nav-prev,
				.owl-custom-nav-next {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: rem(53);
					color: $white;
					font-size: rems(40);
					transition: all .3s ease-in-out;

					@include hover-focus {
						transform: scale(1.3) translateY(-50%);
					}

					@include tablet-down {
						display: none;
					}
				}

				.owl-custom-nav-prev {
					left: rems(40);
				}

				.owl-custom-nav-next {
					right: rems(40);
				}
			}
		}
	}

	#videos {
		background-color: $halfbaked;

		.container {
			h2 {
				margin-bottom: rems(53);
				display: flex;
				justify-content: center;
				padding-left: rems(50);

				&::after {
					content: "";
					display: block;
					width: rems(62);
					height: rems(66);
					transform: translate(rems(3), rems(-16));
					pointer-events: none;
					background: url('../img/icon/decals/star.svg') no-repeat;
					background-size: contain;
				}
			}

			#video-carousel {

				.owl-custom-nav-prev,
				.owl-custom-nav-next {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: rem(53);
					color: $white;
					font-size: rems(40);
					transition: all .3s ease-in-out;

					@include hover-focus {
						transform: scale(1.3) translateY(-50%);
					}
				}

				.owl-custom-nav-prev {
					left: rems(-50);
				}

				.owl-custom-nav-next {
					right: rems(-50);
				}
			}
		}
	}

	#about {
		display: flex;
		background-color: $orchid;
		background-image: url(../img/bg/bg1.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		overflow-y: hidden;

		@media screen and (max-width: 1275px) {
			flex-direction: column;
		}

		.img-wrapper {
			// height: auto;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			object-fit: contain;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 1440px) {
				right: rems(-50);
			}

			@media screen and (max-width: 1340px) {
				right: rems(-100);
			}

			@media screen and (max-width: 1275px) {
				position: static;
				transform: none;
				width: 100%;
				overflow: hidden;
				object-position: center;

				height: 80vw;

			}

			@include tablet-down {
				height: 100vw;
			}

			img {
				width: 100%;
			}
		}

		.container {
			padding: rems(124) 0;
			margin-left: rems(132);

			@media screen and (max-width: 1440px) {
				margin-left: rems(100);
			}

			@media screen and (max-width: 1340px) {
				margin-left: rems(70);
			}

			@media screen and (max-width: 1275px) {
				margin-left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
			}

			.split {
				max-width: rems(556);
				margin-right: rems(50);

				@media screen and (max-width: 1275px) {
					margin: 0 rems(20);
					text-align: center;
				}

				.wrapper {

					h2 {
						margin-bottom: rems(32);
						display: flex;
						justify-content: flex-start;

						@media screen and (max-width: 1275px) {
							justify-content: center;
						}

						&::after {
							content: "";
							display: block;
							width: rems(90);
							height: rems(90);
							transform: translate(rems(20), rems(0));
							pointer-events: none;
							background: url('../img/icon/decals/heart.svg') no-repeat;
							background-size: contain;
						}
					}

					.message {
						min-width: 40vw;

						@include phone-down {
							min-width: none;
						}
					}

				}
			}
		}
	}

	#instagram {
		background-color: $halfbaked;

		.container {
			display: flex;
			align-items: flex-start;

			h2 {
				writing-mode: vertical-rl;
				white-space: nowrap;
				font-size: rems(72);
				transform: rotate(180deg);
				margin-right: rems(20);
				position: relative;

				@media screen and (max-width: 1302px) {
					font-size: rems(57);
				}

				@media screen and (max-width: 992px) {
					// font-size: rems(55);
				}

				&::after {
					content: "";
					position: absolute;
					left: 100%;
					top: 98%;
					display: block;
					width: rems(30);
					height: rems(61);
					// transform: translate(rems(20), rems(0));
					pointer-events: none;
					background: url('../img/icon/decals/star.svg') no-repeat;
					background-size: contain;

					@include tablet-down {
						left: 70%;
					}
				}
			}

			#instagram-feed {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				@media screen and (max-width: 1100px) {
					width: 100%;
					// padding: rems(20);
					margin: 0;
				}

				>div {
					width: 33%;
					padding: 1%;

					@media screen and (max-width: 600px) {
						flex: 100%; // Change flex-basis to flex to take full width
						margin: rems(15) rems(50) rems(15) rems(10);
					}

					a {
						position: relative;
						display: block;
						overflow: hidden;
						width: 100%;
						padding-bottom: 100%;

						span {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background-color: rgba(0, 0, 0, 0.7);
							color: $white;
							display: flex;
							align-items: center;
							justify-content: center;
							transition: transform 0.3s;
							transform: translateX(-100%);
							padding: rems(15);
						}

						&:focus,
						&:hover {
							span {
								transform: scale(1.1);
							}
						}
					}
				}
			}
		}
	}

}

footer {
	background-color: $wisteria;
	padding: rems(80) 0;
	width: 100%;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;


		@include tablet-down {
			flex-direction: column-reverse;
		}

		.copyright {
			max-width: rems(850);
			font-size: rems(18);
			color: $black;
			line-height: 1.2em;
			text-transform: uppercase;
			margin-right: rems(30);

			@include tablet-down {
				text-align: center;
				margin-right: 0;
			}

			p {
				a {
					color: $black;

					sme-privacy-exclude {}
				}
			}
		}

		ul.social {
			display: flex;
			align-items: center;
			list-style: none;
			gap: rems(12);
			padding: 0;
			// margin: 0;
			// min-width: rems(275);

			li {

				@include phone-down {
					width: rems(40);
				}

				a {

					img {
						transition: all .3s ease-in-out;
						width: 100%;

						@include hover-focus {
							transform: scale(1.3);
						}
					}
				}
			}
		}
	}
}

#cursor {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	height: rems(75);
	width: auto;
	transform: translate(-10px, -18px);
	display: none;
}